<template>
    <div class="pageContainer cbox syssy">
        <div class="tabBox flexBetween">
            <div class="flexStart" style="padding-left: 15px">
                <el-breadcrumb>
                    <el-breadcrumb-item>实验室管理</el-breadcrumb-item>
                    <el-breadcrumb-item>实验室开出率汇总表</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="tabContent flexCloumn">
            <el-row class="flexBetween" style="flex-wrap: wrap; width: 100%;margin-bottom: 20px;">
                <div class="flex_1 felxStart">
                    <span> 学期：</span>
                    <el-select size="small" v-model="queryForm.term_id" filterable placeholder="学期" style=""
                        @change="$forceUpdate()">
                        <el-option v-for="(item, index) in termList" :key="index" :label="item.title"
                            :value="item.id"></el-option>
                    </el-select>

                    <el-button class="ml10" size="small" type="primary" icon="el-icon-search"
                        style="display: inline-block" @click="queryCourseTable">查询</el-button>

                    <el-button class="ml10" size="small" type="primary" icon="el-icon-plus"
                        style="display: inline-block" @click="addNew">新增</el-button>
                    <span style="color: #e6a23c;font-size: 14px;font-weight: bold;"><i class="el-icon-warning"></i>
                        双击单元格进行编辑，编辑完成后点击提交</span>
                </div>

                <div v-if="cgjvList && cgjvList.length > 0" class="item" style="display: inline-block">
                    <el-button type="text" size="small" @click="exportExcel()">
                        <a href="javascript:;" style="text-decoration: none">实验室开出率汇总表</a>
                    </el-button>
                    <i class="el-icon-download"></i>
                </div>
            </el-row>
            <div class="dividerBar"></div>
            <el-table :data="cgjvList" :span-method="objectSpanMethod" stripe border size="small"
                @row-dblclick="ondbClick" :cell-class-name="getTableIndex" style="width: fit-content; width: 100%;">
                <el-table-column prop="kc_type_name" label="课程类别" width="60" align="center"></el-table-column>
                <el-table-column prop="kc_name" label="课程名称" min-width="150" align="center"></el-table-column>
                <el-table-column prop="rk_name" label="任课老师" min-width="80" align="center"></el-table-column>
                <el-table-column prop="class_name" label="班级" min-width="80" align="center"></el-table-column>
                <el-table-column prop="jh_num" label="教学大纲规定的实验、实训数" min-width="80" align="center"></el-table-column>
                <el-table-column prop="bx_num" label="本校开出实验、实训数" min-width="80" align="center"></el-table-column>
                <el-table-column prop="kcl" label="实验、实训自开率%" min-width="80" align="center"></el-table-column>
                <el-table-column prop="zw_num" label="在外单位开出实验、实训数" min-width="80" align="center"></el-table-column>
                <el-table-column prop="zkcl" label="实验、实训总开出率%" min-width="80" align="center"></el-table-column>
                <el-table-column prop="reason" label="未开实验实训项目及原因" min-width="80" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" width="80" fixed="right" align="center">
                    <template slot-scope="scope">
                        <el-tag size="mini" type="danger" style="margin-left: 0px; cursor: pointer"
                            @click="deleteItem(scope.row)"><i class="el-icon-delete"></i>删除</el-tag>

                        <el-tag v-if="userinfo.id == scope.row.teacher_id" size="mini" type="success"
                            style="margin-left: 0px; cursor: pointer" @click="editRow(scope.row)"><i
                                class="el-icon-delete"></i>编辑</el-tag>
                    </template>
                </el-table-column>
            </el-table>



            <el-dialog title="新增课程记录" :visible.sync="dialogVisible" custom-class="width_800 cus_dialog"
                :close-on-click-modal="false" @close="onClose">
                <el-form :model="EditItem" label-width="120px">
                    <el-row>
                        <!-- <el-col :span="12">
                            <el-form-item label="学期" required label-width="150px">
                                <el-select v-model="EditItem.term_id">
                                    <el-option :label="item.title" :value="item.id" v-for="(item, i) in termList"
                                        :key="i"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col> -->
                        <el-col :span="12">
                            <el-form-item label="课程类别" required label-width="150px">
                                <el-select v-model="EditItem.kc_type" filterable>
                                    <el-option :label="item.label" :value="item.value" v-for="(item, i) in kc_types"
                                        :key="i"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="课程名称" required label-width="150px">
                                <el-select v-model="EditItem.kc_id" filterable>
                                    <el-option :label="item.course_name" :value="item.course_id"
                                        v-for="(item, i) in courseList" :key="i"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="班级" required label-width="150px">
                                <el-select v-model="EditItem.class_id" filterable>
                                    <el-option :label="item.name" :value="item.id" v-for="(item, i) in classList"
                                        :key="i"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="教学大纲规定的实验、实训数" required label-width="150px">
                                <el-input-number :min="0" v-model="EditItem.jh_num"></el-input-number>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="本校开出实验、实训数" required label-width="150px">
                                <el-input-number :min="0" v-model="EditItem.bx_num"></el-input-number>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="实验、实训自开率%" required label-width="150px">
                                <el-input-number :min="0" v-model="EditItem.kcl" :precision="2"
                                    :step="0.1"></el-input-number>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="在外单位开出实验、实训数" required label-width="150px">
                                <el-input-number :min="0" v-model="EditItem.zw_num"></el-input-number>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="实验、实训总开出率%" required label-width="150px">
                                <el-input-number :min="0" v-model="EditItem.zkcl" :precision="2"
                                    :step="0.1"></el-input-number>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="未开实验实训项目及原因" required label-width="150px">
                                <el-input type="textarea" v-model="EditItem.reason"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row style="text-align: right">
                        <el-col :span="20">
                            <el-button type="primary" icon="el-icon-check" style="display: inline-block"
                                @click="saveSysMaintain">提交</el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </el-dialog>
            <el-dialog title="修改平均开出率" :visible.sync="aveDialogVisible" custom-class="width_800 cus_dialog"
                :close-on-click-modal="false" @close="onClose2">
                <el-form :model="EditItem2" label-width="120px">
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="文化课实验平均自开率(%)" required label-width="250px">
                                <el-input-number :min="0" v-model="EditItem2.whk_kcl" :precision="2"
                                    :step="0.1"></el-input-number>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="专业课实验平均自开率(%)" required label-width="250px">
                                <el-input-number :min="0" v-model="EditItem2.zyk_kcl" :precision="2"
                                    :step="0.1"></el-input-number>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row style="text-align: right">
                        <el-col :span="20">
                            <el-button type="primary" icon="el-icon-check" style="display: inline-block"
                                @click="saveAvekcl">提交</el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { ExportBriefDataDocx, getBase64Sync } from "@/utils/exportBriefDataDocx";

export default {

    data() {
        return {
            userinfo: {},
            dialogVisible: false,
            aveDialogVisible: false,
            report_year: "",
            week: "",
            year_week: new Date(),
            sysID: 0,
            sysName: "",
            sysList: [],
            kc_types: [
                { label: '文化课', value: 'kc_1' },
                { label: '专业课', value: 'kc_2' }
            ],
            courseList: [],
            classList: [],
            activeTabIndex: 0,
            activeIndex: 0, //教室
            queryForm: {},
            termList: [],
            termIdInit: "",
            value: "",
            cgjvList: [],
            sysInfo: {},
            EditItem: {
                jh_num: 0,
                bx_num: 0,
                kcl: 0,
                zw_num: 0,
                zkcl: 0
            },
            EditItem2: {
                whk_kcl: 0,
                zyk_kcl: 0
            },
            teacherList: [],
            kc_1_count: 0,
            kc_2_count: 2,
            whk_kcl: 0,
            zyk_kcl: 0,
            page: {
                current_page: 1,
                per_page: 20,
                count: 0,
                pageSizes: [10, 50, 100],
            },
        };
    },
    mounted() {


        this.getTerm().then((_) => {
            this.getSysList();
            this.getCourse();
        });

        this.getteacherList()
        this.$http.post("/api/get_teacher_info", {}).then((res) => {
            this.userinfo = res.data;
        });

        if (this.$route.query.roomId) {
            this.sysID = Number(this.$route.query.roomId);
        }
    },
    methods: {
        getTableIndex({ row, column, rowIndex, columnIndex }) {
            row.index = rowIndex;
            column.index = columnIndex;
        },
        ondbClick(row, column, event) {
            if (row.index != this.cgjvList.length - 1) {
                this.editRow(row)
            } else {
                this.editLastRow(row)
            }
        },
        editLastRow(e) {
            this.EditItem2.whk_kcl = e.rk_name;
            this.EditItem2.zyk_kcl = e.reason;
            this.aveDialogVisible = true;
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (rowIndex == this.kc_1_count + this.kc_2_count) {
                if (columnIndex == 0) {
                    return {
                        rowspan: 1,
                        colspan: 2
                    }
                } else if (columnIndex == 2) {
                    return {
                        rowspan: 1,
                        colspan: 4
                    }
                } else if (columnIndex == 6) {
                    return {
                        rowspan: 1,
                        colspan: 3
                    }
                } else if (columnIndex == 9) {
                    return {
                        rowspan: 1,
                        colspan: 2
                    }
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0,
                    }
                }
            }
            if (columnIndex == 0) {

                if (rowIndex == 0) {
                    return {
                        rowspan: this.kc_1_count ? this.kc_1_count : this.kc_2_count,
                        colspan: 1,
                    }
                } else if (rowIndex === this.kc_1_count) {
                    return {
                        rowspan: this.kc_2_count ? this.kc_2_count : 1,
                        colspan: 1
                    }
                } else if (rowIndex != this.kc_1_count + this.kc_2_count) {
                    return {
                        rowspan: 0,
                        colspan: 0,
                    }
                }
            }

        },

        getCourse() {
            this.$http
                .post("/api/t_sch_syskc_list", {
                    pagesize: 200,
                    term_id: this.queryForm.term_id,
                })
                .then((res) => {
                    let courseList = res.data;

                    this.courseList = this._lo.uniqBy(courseList, (item) => item.course_id);

                    this.getTeacherClass()
                });
        },

        getTeacherClass() {
            this.$http.post("/api/t_sch_syskc_class_list", { term_id: this.queryForm.term_id }).then((res) => {
                this.classList = res.data.data
            });

        },

        getteacherList() {
            this.$http.post('/api/t_sch_teachers_simple_list').then((res) => {
                this.teacherList = res.data.data;
            })
        },
        addNew() {
            this.dialogVisible = true;
        },
        getcgjvList() {
            this.$http
                .post("/api/t_sch_syskc_kcl_list", {
                    pagesize: 90000000,
                    term_id: this.queryForm.term_id
                })
                .then((res) => {
                    this.kc_1_count = res.data.kc_1_count;
                    this.kc_2_count = res.data.kc_2_count;
                    this.cgjvList = res.data.data;
                    this.cgjvList.push({ kc_type_name: '文化课实验平均自开率(%)', rk_name: res.data.avekcl.whk_kcl, kcl: '专业课实验、实训平均自开率(%)', reason: res.data.avekcl.zyk_kcl })
                    this.page.count = res.data.data.length;
                    this.EditItem2.id = res.data.avekcl.id;
                    this.whk_kcl = res.data.avekcl.whk_kcl;
                    this.zyk_kcl = res.data.avekcl.zyk_kcl;
                });
        },

        getTerm() {
            return new Promise((resolve) => {
                this.$http.post(`/api/t_sch_terms_list`).then((res) => {
                    if (res && res.data.data && res.data.data.length > 0) {
                        this.termList = res.data.data;
                        res.data.data &&
                            res.data.data.map((a) => {
                                if (
                                    this.globalCurrentDate > a.term_start &&
                                    this.globalCurrentDate < a.term_end
                                ) {
                                    this.termIdInit = a.id;
                                    this.queryForm.term_id = this.termIdInit;
                                }
                            });
                        if (!this.termIdInit) {
                            this.termIdInit = res.data.data[0].id;
                            this.queryForm.term_id = this.termIdInit;
                        }
                    }
                    this.$forceUpdate();
                    resolve();
                });
            });
        },
        getSysList() {
            this.$http
                .post("/api/syskcb_rooms", {
                    pagesize: 2000,
                    term_id: this.queryForm.term_id,
                })
                .then((res) => {
                    this.sysList = res.data;
                    if (this.sysID) {
                        this.sysName = this.sysList
                            .filter((s) => s.id === this.sysID)
                            .map((s) => s.room_name);
                        this.sysInfo = this.sysList.filter((s) => s.id === this.sysID)[0];
                    } else if (this.sysList.length > 0) {
                        this.sysID = this.sysList[0].id;
                        this.sysName = this.sysList[0].room_name;
                        this.sysInfo = this.sysList[0];
                    }
                    this.getData();
                });
        },

        getData() {
            //获取采购清单
            this.cgjvList = [];
            this.$forceUpdate();
            this.getcgjvList();
        },

        queryCourseTable() {
            this.sysID = "";
            this.getSysList();
        },

        chooseSys() {
            this.sysList.map((a, ia) => {
                if (this.sysID == a.id) {
                    this.sysInfo = a;
                    this.sysID = a.id;
                    this.sysName = a.room_name;
                    this.activeIndex = ia;
                    this.getData();
                    this.jump(ia);
                    return;
                }
            });
        },
        getSchoolTimetable(row) {
            this.sysInfo = row;
            this.sysID = row.id;
            this.sysName = row.room_name;
            this.sysList.map((a, ia) => {
                if (a.id == row.id) {
                    this.activeIndex = ia;
                    return;
                }
            });
            this.getData();
        },

        changeDate(e) {
            let str = this.$moment(this.year_week).utcOffset(480).format("yyyy-WW");
            this.weekStart = this.$moment(this.year_week)
                .utcOffset(480)
                .format("yyyy-MM-DD");
            this.weekEnd = this.$moment(this.year_week)
                .add(6, "day")
                .utcOffset(480)
                .format("yyyy-MM-DD");

            let arr = str.split("-");
            this.report_year = arr[0];
            this.week = arr[1];

            this.getData();
        },

        deleteItem(e) {
            this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.$http
                    .post("/api/t_sch_syskc_kcl_list_delete", { id: e.id })
                    .then((res) => {
                        this.$message.success("删除成功");
                        this.getData();
                    });
            });
        },
        editRow(e) {
            this.EditItem = e;
            // console.log(this.EditItem);
            this.dialogVisible = true;
        },

        jump(index) {
            let target = document.querySelector(".listBox");

            let scrollItems = document.querySelectorAll(".listItem");

            // 判断滚动条是否滚动到底部

            if (target.scrollHeight <= target.scrollTop + target.clientHeight) {
                this.activeStep = index;
            }

            let total = scrollItems[index].offsetTop - scrollItems[0].offsetTop; // 锚点元素距离其offsetParent(这里是body)顶部的距离(待滚动的距离)

            let distance = document.querySelector(".listBox").scrollTop; // 滚动条距离滚动区域顶部的距离

            // let distance = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset // 滚动条距离滚动区域顶部的距离(滚动区域为窗口)

            // 滚动动画实现, 使用setTimeout的递归实现平滑滚动，将距离细分为50小段，10ms滚动一次

            // 计算每一小段的距离

            let step = total / 50;

            if (total > distance) {
                smoothDown(document.querySelector(".listBox"));
            } else {
                let newTotal = distance - total;

                step = newTotal / 50;

                smoothUp(document.querySelector(".listBox"));
            }

            // 参数element为滚动区域

            function smoothDown(element) {
                if (distance < total) {
                    distance += step;

                    element.scrollTop = distance;

                    setTimeout(smoothDown.bind(this, element), 10);
                } else {
                    element.scrollTop = total;
                }
            }

            // 参数element为滚动区域

            function smoothUp(element) {
                if (distance > total) {
                    distance -= step;

                    element.scrollTop = distance;

                    setTimeout(smoothUp.bind(this, element), 10);
                } else {
                    element.scrollTop = total;
                }
            }
        },

        toBase64(url) {
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.onload = function () {
                    const reader = new FileReader();
                    reader.onloadend = function () {
                        let dataURL = reader.result;
                        const base64Regex =
                            /^data:image\/(png|jpg|jpeg|svg|svg\+xml);base64,/;
                        if (!base64Regex.test(dataURL)) {
                            return false;
                        }
                        const stringBase64 = dataURL.replace(base64Regex, "");
                        let binaryString;
                        if (typeof window !== "undefined") {
                            binaryString = window.atob(stringBase64);
                        } else {
                            binaryString = Buffer.from(stringBase64, "base64").toString(
                                "binary"
                            );
                        }
                        const len = binaryString.length;
                        const bytes = new Uint8Array(len);
                        for (let i = 0; i < len; i++) {
                            const ascii = binaryString.charCodeAt(i);
                            bytes[i] = ascii;
                        }
                        resolve(bytes.buffer);
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(xhr.response);
                };
                xhr.onerror = reject;
                xhr.open("GET", url);
                xhr.responseType = "blob";
                xhr.send();
            });
        },

        convertImageToBase64(url, callback) {
            fetch(url)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(
                            "Network response was not ok " + response.statusText
                        );
                    }
                    console.log(1936, response);
                    response.body();
                })
                .then((blob) => {
                    console.log(":::::::::::::::");
                    const reader = new FileReader();
                    reader.onload = (e) => callback(e.target.result);
                    reader.readAsDataURL(blob);
                })
                .catch((error) =>
                    console.error("Error converting image to base64:", error)
                );
        },

        getTermNum(term){
            const start = this.$moment(term.term_start).year();
            const end = this.$moment(term.term_end).year();
            if(start == end){
                return '二'
            }else{
                return '一'
            }
        },

        async exportExcel() {
            let term;
            this.termList.forEach((item)=>{
                if(item.id == this.queryForm.term_id){
                    term = item;
                }
            })
            const data = { kcl: [{ kc_type: '文化课', kcList: [] }, { kc_type: '专业课', kcList: [] }], school_name: '南京高等职业技术学校', whk_kcl: this.whk_kcl, zyk_kcl: this.zyk_kcl, user_name: this.userinfo.username, date: this.$moment().format('YYYY-MM-DD'), year: term.term_year, term: this.getTermNum(term) };
            this.cgjvList.forEach((item, index) => {
                if (index != this.cgjvList.length - 1) {
                    if (item.kc_type == 'kc_1') {
                        data.kcl[0].kcList.push(item)
                    } else {
                        data.kcl[1].kcList.push(item)
                    }
                }
            })
            ExportBriefDataDocx('/static/ImportTemps/教学实验实训开出项目计划与汇总表.docx', data, `教学实验实训开出项目计划与汇总表_${parseInt(Math.random() * 1000000)}.docx`)
        },
        saveSysMaintain() {
            this.EditItem.term_id = this.queryForm.term_id;

            if (!this.EditItem.kc_id) {
                this.$message.error("请选择课程");
                return;
            }
            if (!this.EditItem.kc_type) {
                this.$message.error("请选择课程类型");
                return;
            }
            if (!this.EditItem.class_id) {
                this.$message.error("请选择班级");
                return;
            }
            this.EditItem.rk_name = this.getRK_name();
            this.$http
                .post("/api/t_sch_syskc_kcl_list_edit", this.EditItem)
                .then((res) => {
                    this.getData();
                    this.$message.success("提交成功");
                    this.EditItem = {
                        jh_num: 0,
                        bx_num: 0,
                        kcl: 0,
                        zw_num: 0,
                        zkcl: 0
                    };
                    this.dialogVisible = false;
                });
        },
        saveAvekcl() {
            this.$http
                .post("/api/t_sch_syskc_kcl_average_edit", this.EditItem2)
                .then((res) => {
                    this.getData();
                    this.$message.success("提交成功");
                    this.EditItem2 = {
                        whk_kcl: 0,
                        zyk_kcl: 0
                    };
                    this.aveDialogVisible = false;
                });
        },
        getRK_name() {
            for (let i = 0; i < this.courseList.length; i++) {
                const item = this.courseList[i];
                if (item.course_id == this.EditItem.kc_id) {
                    return item.teacher_name
                }
            }
            return ''
        },
        onClose() {
            this.EditItem = {
                jh_num: 0,
                bx_num: 0,
                kcl: 0,
                zw_num: 0,
                zkcl: 0
            };
        },
        onClose2() {
            this.EditItem2 = {
                whk_kcl: 0,
                zyk_kcl: 0
            };
        },
    },
};
</script>
<style lang="less" type="text/less">
.syssy {
    .el-input.is-disabled .el-input__inner {
        color: #606266;
    }
}

.cepContact .el-col {
    display: flex;
    padding: 0;
    margin: 0;
}

.cepContact .el-row {
    width: 100%;
    padding: 0;
    margin: 0;
}
</style>
<style scoped="scoped" lang="less" type="text/less">
.bgLineHeight {
    background-color: rgba(24, 144, 255, 0.08);
}

.cepContact {
    margin-top: 25px;
    width: 100%;
    height: calc(100% - 25px);
    overflow-y: auto;

    .title {
        font-size: 20px;
        line-height: 50px;
    }

    .form {
        width: 100% - 0px;
        border: 1px solid #c0d2ff;
        border-top: 0;

        div {
            font-size: 14px;
            color: #323232;
            box-sizing: border-box;
        }

        .content {
            width: 100%;
            height: 100%;
            padding: 10px;
            white-space: break-spaces;
            word-break: break-all;
            overflow: hidden;
        }
    }

    .col-row {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .table {
        width: 100%;
    }

    .borderR {
        border-right: 1px solid #c0d2ff;
    }

    .borderB {
        border-bottom: 1px solid #c0d2ff;
    }

    .cell {
        border-left: 1px solid #c0d2ff;
        border-top: 1px solid #c0d2ff;

        min-height: 30px;
        //line-height: 30px;
        //text-align: center;
        white-space: nowrap;
        font-size: 14px;
        word-break: break-all;
        white-space: normal;
    }

    .cell50 {
        min-height: 50px;
        line-height: 50px;
        font-size: 14px;
    }

    .cellMore {
        border-left: 1px solid #c0d2ff;
        border-top: 1px solid #c0d2ff;
        min-height: 50px;
        text-align: left;
        font-size: 14px;
    }
}

.dttable {
    /*table-layout: fixed;*/

    td {
        text-align: center;
        word-wrap: break-word;
        width: auto !important;
    }

    tbody td {
        /*cursor: pointer;*/
    }
}

.cbox {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0;

    div {
        box-sizing: border-box;
    }

    .el-table__empty-block {
        width: 100% !important;
    }

    .tabBox {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid #98b9fc;

        .tabBar {
            height: 100%;
            align-items: end;

            .tabItem {
                height: 30px;
                border: 1px solid #3f4bda;
                margin-left: 15px;
                padding: 0 5px;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                margin-bottom: -1px;
                cursor: pointer;
            }

            .activeTab {
                background-color: #2469f3;
                border: 0;
                color: #fff;
            }

            .activeTabTgt {
                background-color: #3e4ddb;
                border: 0;
                color: #fff;
            }
        }
    }

    .tabContent {
        padding: 20px 15px;
        height: calc(100% - 40px);

        .dialog_left {
            width: 300px;
            height: 100%;
            overflow: hidden;

            .bgGrey {
                margin-top: 20px;
                padding: 20px 0;
                height: calc(100% - 20px);
                border-radius: 15px;
                background-color: rgba(24, 144, 255, 0.08);

                .listBox {
                    height: 100%;
                    padding: 0 15px;
                    overflow-y: auto;
                    height: calc(100% - 50px);

                    .listItem {
                        margin-bottom: 20px;
                        cursor: pointer;

                        .dotted {
                            margin-right: 10px;
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background-color: #7a7a7a;
                        }

                        .title {
                            color: #7a7a7a;
                            font-weight: 700;
                        }

                        .icon {
                            margin-left: 10px;
                            color: #3073f7;
                            font-weight: 700;
                        }
                    }

                    .activeItem {
                        .dotted {
                            background-color: #3073f7;
                        }

                        .title {
                            color: #3073f7;
                        }
                    }
                }
            }
        }

        .dialog_right {
            margin-left: 30px;
            width: calc(100% - 330px);
            height: 100%;
            overflow: hidden;

            .bcontent {
                width: 100%;
                height: 100%;
                overflow: hidden;
            }

            .formBox {
                width: 100%;
                height: calc(100% - 50px);
                margin-top: 30px;

                .tabBox {
                    width: 100%;
                    height: 40px;
                    border-bottom: 1px solid #98b9fc;
                    align-items: end;

                    .tabBar {
                        height: 100%;
                        align-items: end;
                        padding-left: 40px;

                        .tabItem {
                            height: 30px;
                            border: 1px solid #3f4bda;
                            margin-right: 15px;
                            padding: 0 5px;
                            border-top-left-radius: 8px;
                            border-top-right-radius: 8px;
                            margin-bottom: -1px;
                            cursor: pointer;
                            font-size: 14px;
                            white-space: nowrap;
                        }

                        .activeTab {
                            background-color: #2469f3;
                            border: 0;
                            color: #fff;
                        }

                        .activeTabTgt {
                            background-color: #3e4ddb;
                            border: 0;
                            color: #fff;
                        }
                    }
                }

                .tabContent {
                    width: 100%;
                    height: calc(100% - 100px);
                    padding-left: 40px;
                }
            }

            .targetItem {
                margin-bottom: 10px;
                width: 100%;
            }
        }
    }
}
</style>
